import React, {PureComponent} from 'react';
import CenterBlock from "../CenterBlock";
import './About.scss';
import {inject, observer} from "mobx-react";
import { Player } from 'video-react';

@inject('Store')
@observer
class About extends PureComponent {
	render() {
		const {Store} = this.props;
		return (
			<section className="about_block">
				<CenterBlock>
					<h2>{Store.content.zagolovok_bloka}</h2>
					<div className="about_position">
						<div className="about_position_block">
							<div className="title">{Store.content.podzagolovok_bloka}</div>
							<div className="content">{Store.content.opisanie_bloka}</div>
							<div className="content_icon">
								<img src={Store.content.kartinka_pod_opisaniem_bloka} alt={Store.content.podzagolovok_bloka}/>
							</div>
						</div>
						<div className="about_position_block">
							<Player
								playsInline
								poster={Store.content.kartinka_video}
								src={Store.content.video_v_bloke}
							/>
						</div>
					</div>
				</CenterBlock>
			</section>
		);
	}
}

export default About;
