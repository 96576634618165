import React, {PureComponent} from 'react';
import './ResearchItem.scss';
import Interweave from 'interweave';
import PropTypes from 'prop-types';

class ResearchItem extends PureComponent {

	onHandleClick = (e) => {
		this.props.onClick(this.props.index)
	}

	render() {
		return (
			<div onClick={this.onHandleClick} className={`research_item ${this.props.index === this.props.active ? 'active' : ''}`}>
				<div className="title">{this.props.title}</div>
				<div className="author">{this.props.author}</div>
				<div className="content"><Interweave content={this.props.content} /></div>
			</div>
		);
	}
}

ResearchItem.propTypes = {
	title: PropTypes.string.isRequired,
	author: PropTypes.string.isRequired,
	content: PropTypes.string.isRequired,
};

export default ResearchItem;
