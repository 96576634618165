import React, {PureComponent} from 'react';
import './CompositionList.scss';
import PropTypes from 'prop-types';

class CompositionList extends PureComponent {
	render() {
		const style = {
			margin: this.props.margin,
			padding: this.props.padding,
		}
		return (
			<div style={style} className="composition_list">
				{this.props.children}
			</div>
		);
	}
}

CompositionList.propTypes = {
	margin: PropTypes.string,
	padding: PropTypes.string,
}

export default CompositionList;
