import React, {PureComponent} from 'react';
import './HeaderMobile.scss';
import {inject, observer} from "mobx-react";
import Interweave from "interweave";

@inject('Store')
@observer
class HeaderMobile extends PureComponent {
	render() {
		const {Store} = this.props;
		const style = {
			backgroundImage: `url(${Store.content.kartinka_fona})`
		}
		return (
			<section className="header_mobile">
				<div style={style} className="header_mobile_top">
					<div className="logo"><img src={Store.content.logotip} alt={Store.content.nazvanie_produkta}/></div>
				</div>
				<div className="header_mobile_bottom">
					<div className="header_mobile_product"><img src={Store.content.kartinka_produkta} alt=""/></div>
					<div className="header_mobile_product_title">
						<h1>{Store.content.nazvanie_produkta}</h1>
						<span>{Store.content.proizvoditel}</span>
					</div>
					<div className="header_mobile_product_description"><Interweave content={Store.content.opisanie_prodkuta_v_shapke} /></div>
				</div>
			</section>
		);
	}
}

export default HeaderMobile;
