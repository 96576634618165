import React, {PureComponent, Fragment} from 'react';
import {inject, observer} from "mobx-react";
import CenterBlock from "../CenterBlock";
import Interweave from 'interweave';
import './Instruction.scss';

@inject('Store')
@observer
class Instruction extends PureComponent {

	render() {
		const {Store} = this.props;
		const style = {
			backgroundImage: `url(${Store.content.fon_zagolovka_instrukczii})`
		}
		return (
			<Fragment>
				<div style={style} className="instruction_line">
					<CenterBlock>
						<h3>{Store.content.zagolovok_instrukczii}</h3>
						<div className="instruction_download_link">
							{
								Store.content.fajl_instrukczii.map((item, index) => {
									return <a key={index} rel="noopener noreferrer" target="_blank" href={item.file}>{item.zagolovok}</a>
								})
							}
						</div>
					</CenterBlock>
				</div>
				<section className="instruction_block">
					<CenterBlock>
						<div className="instruction_block_top"><Interweave content={Store.content.kontent_instrukczii} /></div>
						<div className="instruction_block_line"/>
						<div className="instruction_block_bottom"><Interweave content={Store.content.rasshifrovka_instrukczii} /></div>
					</CenterBlock>
				</section>
			</Fragment>
		);
	}
}

export default Instruction;
