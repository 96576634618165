import React, {PureComponent} from 'react';
import './Composition.scss';
import CenterBlock from "../CenterBlock";
import Interweave from 'interweave';
import CompositionItem from "../CompositionItem";
import CompositionList from "../CompositionList";
import PropTypes from 'prop-types';

class Composition extends PureComponent {

	renderHeader = (header) => {
		switch (header) {
			case 'h2': return <h2><Interweave content={this.props.title} /></h2>
			case 'h3': return <h3><Interweave content={this.props.title} /></h3>
			default: return <h4><Interweave content={this.props.title} /></h4>
		}
	}

	render() {
		const style = {
			padding: this.props.padding,
			backgroundImage: `url(${this.props.background})`
		}
		const background_line = {
			background: this.props.background_line
		}
		return (
			<section style={style} className="composition">
				<div style={background_line} className="composition_line">
					<CenterBlock>{this.renderHeader(this.props.title_header)}</CenterBlock>
				</div>
				<CenterBlock>
					<CompositionList margin="50px 0 0 0">
						{
							this.props.array.map((item, index) => {
								return (
									<CompositionItem
										key={index}
										title={item.preimushhestva_zagolovok}
										left={item.preimushhestva_kontent_left}
										right={item.preimushhestva_kontent_right}
									/>
								)
							})
						}
					</CompositionList>
				</CenterBlock>
			</section>
		);
	}
}

Composition.propTypes = {
	background_line: PropTypes.string.isRequired,
	padding: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	background: PropTypes.string.isRequired,
	title_header: PropTypes.string.isRequired,
	array: PropTypes.array.isRequired,
}

export default Composition;
