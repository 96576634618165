import React, {PureComponent} from 'react';
import {inject, observer} from "mobx-react";
import CenterBlock from "../CenterBlock";
import './Header.scss';
import Interweave from 'interweave';

@inject('Store')
@observer
class Header extends PureComponent {
	render() {
		const {Store} = this.props;
		const style = {
			backgroundImage: `url(${Store.content.kartinka_fona})`
		}
		return (
			<section style={style} className="header_block">
				<CenterBlock>
					<div className="logo"><img src={Store.content.logotip} alt={Store.content.nazvanie_produkta}/></div>
				</CenterBlock>
				<div className="header_block_line">
					<CenterBlock>
						<div className="header_position_content">
							<div className="header_product"><img src={Store.content.kartinka_produkta} alt=""/></div>
							<div className="header_product_title">
								<h1>{Store.content.nazvanie_produkta}</h1>
								<span>{Store.content.proizvoditel}</span>
							</div>
							<div className="header_product_description"><Interweave content={Store.content.opisanie_prodkuta_v_shapke} /></div>
						</div>
					</CenterBlock>
				</div>
			</section>
		);
	}
}

export default Header;
