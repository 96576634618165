import React, {PureComponent} from 'react';
import {inject, observer} from "mobx-react";
import './Research.scss';
import CenterBlock from "../CenterBlock";
import ResearchItem from "../ResearchItem";

@inject('Store')
@observer
class Research extends PureComponent {

	state = {
		active: 0,
	}

	onHandleClick = (e) => {
		if (this.state.active === e) {
			this.setState({active: 0})
		} else {
			this.setState({active: e})
		}
	}

	render() {
		const {Store} = this.props;
		const style = {
			backgroundImage: `url(${Store.content.fon4})`
		}
		return (
			<section style={style} className="research_block">
				<div className="research_line"><h3>{Store.content.issledovaniya_zagolovok}</h3></div>
				<CenterBlock>
					<div className="research_block_list">
						{
							Store.content.issledovaniya_spiok.map((item, index) => {
								return (
									<ResearchItem
										key={index + 1}
										index={index + 1}
										active={this.state.active}
										title={item.zagolovok_issledovaniya}
										author={item.avtor_issledovaniya}
										content={item.tekst_issledovaniya}
										onClick={this.onHandleClick}
									/>
								)
							})
						}
					</div>
				</CenterBlock>
			</section>
		);
	}
}

export default Research;
