import React from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import {Provider} from "mobx-react";
import Store from './store/Store';

const Root = (
  <Provider Store={Store}>
    <App />
  </Provider>
);

ReactDOM.render(Root, document.getElementById('root'));

