import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import './CustomCheckBox.scss';

class CustomCheckBox extends PureComponent {

	state = {
		checked: false,
	}

	onHandleClick = () => {
		this.setState({checked: !this.state.checked},
			() => this.props.onClick({[this.props.name]: this.state.checked}))
	}

	render() {
		return (
			<label className="custom_checkbox">
				<input onChange={this.onHandleClick} type="checkbox" name={this.props.name} checked={this.state.checked}/>
				<span>{this.props.title}</span>
			</label>
		);
	}
}

CustomCheckBox.propTypes = {
	onClick: PropTypes.func.isRequired,
	name: PropTypes.string.isRequired,
	checked: PropTypes.bool.isRequired,
	title: PropTypes.string.isRequired,
};

export default CustomCheckBox;
